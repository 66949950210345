<template>
    <div id="app">
        <TopBar></TopBar>
        <main>
            <div class="container grid-10-2">
                <div class="left-side">
                    <router-view />
                </div>
                <Shortcuts></Shortcuts>
            </div>
        </main>
        <Footer></Footer>
        <Cookies></Cookies>
        <Popup></Popup>
    </div>
</template>

<script>
import TopBar from "./components/TopBar";
import Shortcuts from "./components/Shortcuts";
import Footer from "./components/Footer";
import Cookies from "./components/Cookies";
import Popup from "./components/Popup";

import axios from "axios";
import Glowna from "@/pages/Glowna.vue";
import Aktualnosci from "@/pages/Aktualnosci.vue";
import Tekstowa from "@/pages/Tekstowa.vue";
import Kontakt from "@/pages/Kontakt.vue";
import Wpis from "@/pages/Wpis.vue";
import NotFound from "@/pages/NotFound.vue";

// old legacy files

//js
// import "./assets/js/jquery-3.6.0.min";
// import "./assets/js/magnific.min";
import "./assets/js/admin";
import "./assets/js/funkcje";
// import "./assets/js/jquery.fancybox-1.3.1";
import "./assets/js/jquery.imgareaselect.min.js";
import "./assets/js/zdzislowicz-hashmenu-1.1";
// import "./assets/js/zdzislowicz-animations-1.2";
import "./assets/js/zdzislowicz-cookies-1.1";
import "./assets/js/zdzislowicz-burger-1.6";
import "./assets/js/zdzislowicz-contact-1.5";
import "./assets/js/zdzislowicz-slider-1.7.js";

// css
import "./assets/css/magnific.min.css";
// import "./assets/css/zdzislowicz-animations-1.0.min.css";
// import "./assets/css/jquery.fancybox-1.3.1.css";

export default {
    name: "EmausRzeszow",
    data() {
        return {
            textPages: [],
            newsList: [],
        };
    },
    components: {
        TopBar: TopBar,
        Shortcuts: Shortcuts,
        Footer: Footer,
        Cookies: Cookies,
        Popup: Popup,
    },
    methods: {
        addTextPagesRoutes() {
            this.textPages.forEach((x) => {
                let komponent;

                if (x.szablon == "strony/strona-tekstowa") komponent = Tekstowa;
                if (x.szablon == "strony/glowna") komponent = Glowna;
                if (x.szablon == "strony/aktualnosci") komponent = Aktualnosci;
                if (x.szablon == "strony/kontakt") komponent = Kontakt;

                this.$router.addRoute({
                    path: x.link,
                    name: x.title,
                    gallery: x.gallery,
                    component: komponent,
                    props: { pageData: x },
                    meta: {
                        title: x.title,
                    },
                });
            });
            this.$router.addRoute({
                path: "*",
                component: NotFound,
                meta: {
                    title: "Strony nie znaleziono",
                },
            });
        },

        getTextPages() {
            axios
                .get("/api/textPages/textPages.php")
                .then((response) => {
                    // to dziala :) - "yarn json-server db.json" - .get("http://localhost:3000/textPages")
                    // axios.get("https://emaus.zdzislowicz.dev/api/textPages/textPages.php").then((response) => {
                    // axios.get("http://localhost:3000/textPages").then((response) => {
                    // axios.get(`http://localhost:3000/textPages/${id}`).then((response) => {
                    this.textPages = response.data;
                    this.addTextPagesRoutes();
                })
                .catch((e) => new Error(e));
        },
        getNewsList() {
            axios
                .get("/api/newsList/newsList.php")
                .then((response) => {
                    // axios.get("https://emaus.zdzislowicz.dev/api/newsList/newsList.php").then((response) => {
                    // axios.get("http://localhost:3000/newsList").then((response) => {
                    this.newsList = response.data;
                    // console.log(response.data)
                    this.addNewsItemsRoutes();
                })
                .catch((e) => new Error(e));
        },
        addNewsItemsRoutes() {
            this.newsList.forEach((x) => {
                // const alreadyExistingRoute = this.$router.matcher.getRoutes().find((route) => x.link.includes(route.path));

                // if (!alreadyExistingRoute) {
                this.$router.addRoute({
                    path: x.link,
                    name: x.title,
                    gallery: x.gallery,
                    component: Wpis,
                    props: { pageData: x },
                    meta: {
                        title: x.title,
                    },
                });
                // }
            });
        },
    },
    created() {
        this.getTextPages();
    },
    mounted() {
        this.getNewsList();
    },
};
</script>

<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

// $colorCream: #f5f7da;
// $green: rgba(171, 225, 98, 1);
// $greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;
$link: #40791a; //#2a6637;
$text: #222222;

@mixin gradient {
    background-color: rgba(171, 225, 98, 1);
    background-image: linear-gradient(0deg, rgba(171, 225, 98, 1) 0%, rgba(137, 186, 58, 1) 100%);
    @media screen and (max-width: $smalltablet) {
        background-color: rgba(171, 225, 98, 1);
        background-image: linear-gradient(0deg, rgba(171, 225, 98, 1) 0%, rgba(137, 186, 58, 1) 30%);
    }
}
@mixin gradientReverse {
    background-color: rgba(137, 186, 58, 1);
    background-image: linear-gradient(0deg, rgba(137, 186, 58, 1) 0%, rgba(171, 225, 98, 1) 100%);
    @media screen and (max-width: $smalltablet) {
        background-color: rgba(137, 186, 58, 1);
        background-image: linear-gradient(0deg, rgba(137, 186, 58, 1) 0%, rgba(171, 225, 98, 1) 30%);
    }
}

body {
    font-family: $fontMontserrat;
    @include fontSize13px;
    padding: 0px;
    margin: 0px;
    background: $colorCream;
    position: relative;
    line-height: 1.4;
    p {
        color: $text;
    }
    a {
        color: $link;
        transition: 0.3s ease-in all;
        &:hover {
            color: $mainDarker;
            text-decoration: underline;
        }
    }
}

.no-margin {
    margin: 0 !important;
}

img {
    border: none;
}

#app {
    background: url("./assets/img/bg-2.jpg") top center no-repeat;
}

main {
    overflow: hidden;
    .grid-10-2 {
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 30px;
        margin-top: 30px;
        @media screen and (max-width: $tablet) {
            grid-template-columns: 100%;
            margin-top: 15px;
        }
    }
}

.main-content {
    margin-top: 30px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 11px 6px rgb(175, 175, 175);
    -moz-box-shadow: 0px 0px 11px 6px rgba(175, 175, 175, 0.3);
    box-shadow: 0px 0px 11px 6px rgba(175, 175, 175, 0.3);
    padding: 30px;
    border-radius: 5px;
    header {
        h1 {
            @include fontSize18px;
            color: $link;
            padding-bottom: 15px;
            border: 0;
        }
    }
    ol,
    ul {
        padding-left: 15px;
    }
    @media screen and (max-width: $smalltablet) {
        padding: 15px;
    }
}

.gallery {
    //   overflow: hidden;
    padding: 30px 0 0 0;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(5, 1fr);
        li {
            // padding: 10px 0 10px 0;
            text-align: center;
            width: 100%;
            height: auto;
            position: relative;
            z-index: 1;
            picture {
                overflow: hidden;
                img {
                    transition: 0.3s ease-in all;
                }
            }

            &:hover {
                cursor: pointer;
                picture {
                    img {
                        transform: scale(1.1);
                    }
                }
                // &:after {
                //     opacity: 1;
                // }
            }
            a {
                z-index: 1;
                display: block;
            }
            img {
                // border: 1px $border solid;
                padding: 1px;
                width: 100%;
                height: auto;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        ul {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media screen and (max-width: $smalltablet) {
        ul {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media screen and (max-width: $mobile) {
        ul {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

#GoRODOcertyfikat {
    position: absolute;
    top: 50%;
    @media screen and (max-width: $bigtablet) {
        right: 0;
        left: unset;
    }

    @media screen and (max-width: $tablet) {
        bottom: 120px;
        top: unset;
        // left: 0;
        img {
            width: 50px;
        }
    }
    @media screen and (max-width: $smalltablet) {
        bottom: 4%;
        right: 5px;
        div {
            width: 50px !important;
        }
        img {
            width: 50px;
        }
    }
}
.ideafairplay-info p {
    font-size: 14px !important;
}
div#ideafairplay-boxslide-button-top,
div#ideafairplay-boxslide-button-bottom {
    font-size: 14px !important;
}
div#ideafairplay-boxslide-content div.ideafairplay-footer {
    font-size: 15px !important;
}
div#ideafairplay-boxslide-content {
    @media screen and (max-width: $mobile) {
        width: 205px !important;
    }
}
div#ideafairplay-boxslide {
    @media screen and (max-width: $mobile) {
        // width: 205px !important;
        top: 25% !important;
    }
}

div#fanimani-1proc-box p {
    color: white !important;
}

#fanimani-1proc-cta-close {
    color: white !important;
}
</style>
