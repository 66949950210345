import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //tutaj się dodają routy, z APP za pomocą addRoute. Robimy to tam, ponieważ w komponencie Routera nie powinniśym nic pobierać.
  ],
  scrollBehavior(to, from, savedPosition) {
      return  { y: 0 };
  },
})


const DEFAULT_TITLE = 'Emaus Rzeszów';
router.afterEach((to, from) => {
  // document.title = to.meta.title || DEFAULT_TITLE;
  document.title = to.meta.title + " - Emaus Rzeszów" || DEFAULT_TITLE;
});

export default router
