<template>
    <aside class="slider" v-if="slider">
        <ul class="content">
            <li v-for="(item, index) in slider" :key="index" class="cover-bg" :style="{ backgroundImage: `url(${item.imagePath})` }">
                <!-- <li v-for="(item, index) in slider" :key="index" class="cover-bg" :style="{'background-image': 'url(' + require('../assets/img/slider.png') + ')'}"> -->
                <div class="slider-text" v-if="item">
                    <div>
                        <h2>{{ item.title }}</h2>
                        <p>{{ item.subtitle }}</p>
                    </div>
                </div>
            </li>
            <!-- <li class="cover-bg" :style="{ 'background-image': 'url(' + item.imagePath + ')' }"></li> -->

            <li>
                <!-- <picture><img src="@/assets/img/slider.png" alt="" /></picture> -->
                <picture><img :src="slider[0].imagePath" alt="" /></picture>
            </li>
        </ul>
        <ul v-if="slider.length > 1" class="nav fade fadein fadeinstart">
            <li v-for="(item, index) in slider" :key="index">
                <button :class="[index == 0 ? 'active' : '']"><span class="visuallyhidden">(Aktywny slajd)</span></button>
            </li>
        </ul>
    </aside>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            slider: null,
        };
    },
    name: "Slider",

    methods: {
        getSlider() {
            axios.get("/api/slider/slider.php").then((response) => {
                // axios.get("https://emaus.zdzislowicz.dev/api/slider/slider.php").then((response) => {
                // axios.get("http://localhost:3000/slider").then((response) => {
                this.slider = response.data;
            }).catch(e => new Error(e) );
        },
        sliderRun() {
            $('.slider').zSlider({
                speed : 5000,
                duration : 800,
                startDelay: 3000
            });
        }
    },
    mounted() {
        this.getSlider();
    },
    updated() {
        this.sliderRun();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

$whiteFE: #fefefe;
$whiteF5: #f5f5f5;
$gray: #cecece;
$colorCream: #f5f7da;
$green: rgba(171, 225, 98, 1);
$greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;

@mixin gradient {
    background-color: rgba(171, 225, 98, 1);
    background-image: linear-gradient(0deg, rgba(171, 225, 98, 1) 0%, rgba(137, 186, 58, 1) 100%);
}
@mixin gradientReverse {
    background-color: rgba(137, 186, 58, 1);
    background-image: linear-gradient(0deg, rgba(137, 186, 58, 1) 0%, rgba(171, 225, 98, 1) 100%);
}
@mixin button {
    font-size: 100%;
    font-family: inherit;
    border: none;
    background: transparent;
    .visuallyhidden {
        display: none;
    }
    &:hover {
        opacity: 0.7;
    }
}

.slider {
    position: relative;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 3;
        padding: 15px;
        background: $whiteF5;
        position: relative;
        h2 {
            color: $whiteFE;
            text-align: center;
            max-width: 750px;
            line-height: 1.2;
            font-weight: 700;
        }
        @media screen and (max-width: $tablet) {
            h2 {
                text-align: left;
            }
        }
    }
    .content {
        width: 100%;
        li {
            background-position: center;
            .slider-text {
                width: 60%;
                // // height: 79px;
                padding: 20px;
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 30px;
                background: $mainDarker;
                height: fit-content;
                min-height: 125px;
                bottom: 30px;
                left: 0;
                // background: url("../assets/img/slider-text-bg.png") no-repeat bottom 10px center;
                div {
                    display: flex;
                    flex-direction: column;
                    align-content: flex-start;
                    justify-content: flex-start;
                    padding: 0 15px;
                }
                h2 {
                    position: relative;
                    bottom: 10px;
                    font-family: $fontMontserrat;
                    font-size: 20px;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                }

                p {
                    font-family: $fontMontserrat;
                    font-size: 12px;
                    color: #fff;
                    padding: 0;
                    display: block;
                }
                @media screen and (max-width: $smalltablet) {
                    padding: 8px;
                    min-height: 100px;
                    display: none;
                    div {
                        padding: 0;
                    }
                    h2 {
                        font-size: 15px;
                    }
                }
            }
        }
        picture {
            // height: auto;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }
    }

    .nav {
        position: absolute;
        z-index: 9;
        width: 100%;
        height: 36px;
        left: unset;
        right: 10px;
        bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 auto;
        padding: 0;

        li {
            margin: 0px 3px;
            display: inline-block;
            // width: 8px;
            // height: 8px;

            button {
                @include button;
                display: block;
                width: 15px;
                height: 15px;
                z-index: 1;
                cursor: pointer;
                transition: 0.3s ease all;
                position: relative;
                background-color: $gray;
                &.active,
                &:hover {
                    opacity: 1;
                    background-color: $mainDarker;
                }
            }
        }
        @media screen and (max-width: $smalltablet) {
            button {
                width: 8px;
                height: 8px;
            }
        }
    }
}
</style>
