<template>
  <header class="topbar">
    <div class="container">
      <div class="top">
          <div class="logo">
              <router-link to="/"><img src="@/assets/img/logo.png" alt="Stowarzyszenie EMAUS-RZESZÓW" title="Stowarzyszenie EMAUS-RZESZÓW" /></router-link>
          </div>
      </div>
      <div class="menu">
          <div class="left-home fl">
              <router-link to="/"><img src="@/assets/img/home-ico.png" alt="Przejdź do strony głównej" title="Przejdź do strony głównej" /></router-link>
          </div>
          <div class="right-bg">
              <nav class="navbar main-menu zMenu">
                  <ul>
                    <li v-for="item in menu" :key="item.name"><router-link :to="`/${item.href}/`">{{ item.name }}</router-link></li>
                  </ul>
              </nav>
              <button class="burgermenu" type="button" title="Kliknij, by otworzyć menu"></button>
          </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      menu: null,
      // homeIco: '',
      // top: ''
    }
  },
  name: 'TopBar',
  
  methods: {
    getMenu() {
      axios.get("/api/menu/menu.php").then((response) => {
          // axios.get("https://emaus.zdzislowicz.dev/api/menu/menu.php").then((response) => {
          // axios.get("http://localhost:3000/menu").then((response) => {
          // console.log(response.data)
          this.menu = response.data;
      }).catch(e => new Error(e) );
    },
  },
  mounted() {
    this.getMenu();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";


$whiteFE: #fefefe;
$whiteF5: #f5f5f5;
$colorCream: #f5f7da;
$green: rgba(171, 225, 98, 1);
$greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;

@mixin gradient {
    background-color: rgba($mainLight, 1);
    background-image: linear-gradient(0deg, rgba($mainLight, 1) 0%, rgba($mainDarker, 1) 100%);
}
@mixin gradientReverse {
    background-color: rgba($mainDarker, 1);
    background-image: linear-gradient(0deg, rgba($mainDarker, 1) 0%, rgba($mainLight, 1) 100%);
}
button {
  border: none;
  font-size: 100%;
  font-family: inherit;
  background: transparent;
  cursor: pointer;
}
.topbar {
  .right-bg {
  height: 35px;
  padding-right: 5px;
  // background: url(./../assets/img/menu-sprites.png) no-repeat scroll 935px 0px transparent;
  display: flex;
  align-items: center;
}

.active-right-bg {
  height: 35px;
  padding-right: 5px;
  // background: url(./../assets/img/menu-sprites.png) no-repeat scroll 922px 0px transparent !important;
}
  .burgermenu {
        width: 35px;
        height: 35px;
        right: 0;
        position: relative;
        margin-left: 10px;
        background: url('../assets/img/svg/hamburger-20x20.svg') center no-repeat;
        background-size: 20px auto;
        
        @media screen and (min-width: $smalltablet) {
            display: none;
        }
        @media screen and (max-width: $mobile) {
            margin-left: 0px;
        }
    }
}

.navbarMobile {
    background: $colorCream;
    height: 100vh; 
    overflow-y: auto;
    overflow-y: auto;
    z-index: 10000000;
    right: auto;
    left: 0; 
    padding-bottom: 60px;
    .top {
        background: $whiteFE;
        height: 80px;
        @media screen and (max-width: $mobile) {
            height: 51px;
        }
        img {
            max-height: 60px;
        }
    }
    .container {
        margin: 0 auto;
        position: relative; 
        overflow: hidden;
        @media screen and (max-width: $smalltablet) {
            max-width: calc(100% - 50px);
        }
    }
    .close {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;
        background: $colorCream;
        button {
            z-index: 99;
            background: url(../assets/img/svg/hamburger-close-42x42.svg) center center no-repeat;
            background-size: 30px auto;
            width: 80px;
            height: 80px;
            top: 0;
            right: 0;
            border: none;
            position: absolute;
        }
        @media screen and (max-width: $mobile) {
            height: 51px;
            button {
                width: 51px;
                height: 51px;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none; 
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        li {
          padding: 5px 0;
          font-size: 15px;
          text-align: center;
          a {
            &.router-link-active, &.router-link-exact-active {
              color: #8c8c8c;
            }
          }
        }
    }
}

.top {
  height: 180px;
  background: url('../assets/img/top-bg.png');
  background-repeat: no-repeat;
  background-position: right;
  // @media screen and (max-width: $smalltablet) {
  //     background-size: cover;
  //     background-position: top right;
  // }
  @media screen and (max-width: $smalltablet) {
      background-size: auto 85px;
      background-position: right bottom;
      height: 85px;
  }
}

.logo {
  padding-top: 50px;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: $smalltablet) {
      padding-top: 10px;
      img {
        max-width: 150px;
      }
  }
}

.menu {
//   height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include gradient;
    border-radius: 5px;
}

.left-home {
    width: 46px;
    height: 35px;
  &:hover {
    @include gradientReverse;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.main-menu {
  font-size: 14px;
  text-transform: uppercase;
  height: 35px;
  width: auto !important;
  /* background: url(./../assets/img/main-menu.jpg) repeat-x;*/
  .wybrana {
    text-decoration: underline;
  }
  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: flex;
  }

  li {
    background: url(./../assets/img/menu-border.jpg) no-repeat left;
    padding-left: 1px;
    &:first-of-type {
      display: none;
    }
  }

  a {
    @include gradient;
    min-width: 120px;
    height: 35px;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 14px;
    color: #fff;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    line-height: 35px;
    transition: 0.3s ease-in all;
    &:hover {
      @include gradientReverse;
      color: $whiteFE;
      text-decoration: none;
    }
    &.router-link-active, &.router-link-exact-active {
      @include gradientReverse;
    }
  }
}

// .main-menu-active {
//   background: url(./../assets/img/main-menu-active.jpg) repeat-x;
// }



</style>
