<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__menu">
                <ul>
                    <li v-for="(item, index) in menu" :key="index">
                        <router-link :to="`/${item.href}/`">{{ item.name }}</router-link>
                    </li>
                </ul>
                <div class="address">
                    <p>Stowarzyszenie Emaus-Rzeszów<br />ul.Batorego 22, 35-005 Rzeszów<br />tel./fax: (+48) 17 85 90 329</p>
                </div>
            </div>
            <div class="footer__realization">
                <p>Realizacja: <a href="https://zdzislowicz.pl" title="Tworzenie stron WWW Rzeszów">Zdzislowicz.pl</a> - <a href="https://zdzislowicz.pl/oferta/">Tworzenie stron WWW Rzeszów</a></p>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            menu: [],
        };
    },
    name: "Footer",
    props: {
        // msg: Number
    },
    methods: {
        getMenu() {
            axios.get("/api/menu/menu.php").then((response) => {
                // axios.get("https://emaus.zdzislowicz.dev/api/menu/menu.php").then((response) => {
                // axios.get("http://localhost:3000/menu").then((response) => {
                this.menu = response.data;
            }).catch(e => new Error(e) );
        },
    },
    mounted() {
        this.getMenu();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";

$colorCream: #f5f7da;
$green: rgba(171, 225, 98, 1);
$greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;

footer {
    .container {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 5px solid #225f2f;
    }
    a:hover {
        text-decoration: underline;
    }
}
.footer__menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ul {
        display: flex;
        list-style: none;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        li {
            padding-right: 20px;
            &:last-of-type {
                padding-right: 0;
            }
            &:first-of-type {
                display: none;
            }
            a {
                transition: 0.3s ease-in all;
                &:hover {
                    text-transform: underline;
                }
                &.router-link-active,
                &.router-link-exact-active {
                    color: #8c8c8c;
                }
            }
        }
    }
    p {
        padding: 0;
    }
    @media screen and (max-width: $smalltablet) {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
        ul {
            justify-content: center;
            flex-wrap: wrap;
        }
        .address {
            margin: 0 auto;
        }
    }
}
.footer__realization {
    @media screen and (max-width: $smalltablet) {
        text-align: center;
    }
}
</style>
