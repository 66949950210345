<template>
  <div class="right-side">
    <div class="right-menu fade fadein">
      <div class="box" v-for="(item, index) in rightMenu" :key="index">
        <div :class="item.class">
          <h2>{{ item.name }}</h2>
        </div>
        <div class="overlay">
          <router-link :to="`/${item.href}/`" :title="item.name"></router-link>
        </div>
      </div>
    </div>
    <div class="right-links">
      <div class="links-top fade fadein">
        <a href="/przekaz-1-5-procent/" class="tax"
          >Przekaż 1,5 % odpisu od podatku na rzecz naszego Stowarzyszenia</a
        >
        <div class="logotype fade fadein">
          <a href="https://www.facebook.com/stowarzyszenieemausrzeszow/"
            title="EMAUS-RZESZÓW na Facebooku"><img
              src="@/assets/img/facebook.jpg"
              width="114"
              height="37"
              alt="EMAUS-RZESZÓW na Facebooku"
              title="EMAUS-RZESZÓW na Facebooku"
          /></a>
          <a href="http://allegro.pl/listing/user.php?us_id=16207657"
            title="EMAUS-RZESZÓW na Allegro"><img
              src="@/assets/img/allegro.jpg"
              width="106"
              height="37"
              alt="EMAUS-RZESZÓW na Allegro"
              title="EMAUS-RZESZÓW na Allegro"
          /></a>
        </div>
      </div>
      <div class="links-bottom fade fadein">
        <h2>Zobacz również</h2>
        <ul>
          <li><a href="http://www.emmaus.pl">www.emmaus.pl</a></li>
          <li><a href="http://www.emaus.lublin.pl">www.emaus.lublin.pl</a></li>
          <li>
            <a href="http://www.emmaus-international.org"
              >www.emmaus-international.org</a
            >
          </li>
          <li>
            <a href="http://www.emmaus-europe.org">www.emmaus-europe.org</a>
          </li>
          <li>
            <a href="http://www.planet-emmaus.org">www.planet-emmaus.org</a>
          </li>
        </ul>
        <a
          class="dotpay" title="Przejdź do płatności"
          href="https://ssl.dotpay.pl/t2/?pid=ana1vm5qp0qzvv4cqdn4e4sobow9fegl"
          ><img src="@/assets/img/dotpay_b5_145x252.png" alt="Dotpay"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      rightMenu: [],
      // isZbiorki: '',
      // isKiermasze: '',
      // isDarowizny: '',
      // isPrzeprowadzki: '',
      // homeIco: '',
      // top: ''
    };
  },
  name: "Shortcuts",

  methods: {
    getRightMenu() {
      axios.get("/api/rightMenu/rightMenu.php").then((response) => {
          // axios.get("https://emaus.zdzislowicz.dev/api/rightMenu/rightMenu.php").then((response) => {
          // axios.get("http://localhost:3000/rightMenu").then((response) => {
          this.rightMenu = response.data;
     }).catch(e => new Error(e) );
    },
  },
  mounted() {
    this.getRightMenu();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

$colorCream: #f5f7da;
// $green: rgba(171, 225, 98, 1);
// $greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;
$link: #40791A; //#2a6637;
$text: #222222;

@mixin gradient {
  background: linear-gradient(
    0deg,
    rgba($mainLight, 1) 0%,
    rgba($mainDarker, 1) 100%
  );
}
@mixin gradientReverse {
  background: linear-gradient(
    0deg,
    rgba($mainDarker, 1) 0%,
    rgba($mainLight, 1) 100%
  );
}

.right-side {
  @media screen and (max-width: $tablet) {
    // display: flex;
    // gap: 30px;
  }
}

.right-menu {
  margin-bottom: 25px;
  width: 100%;
  .box {
    height: 80px;
    margin-bottom: 5px;
    @include gradient;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: 0.3s ease-in all;
    h2 {
      margin: 0px;
      padding: 0;
      text-align: center;
      font-family: $fontMontserrat;
      font-size: 14px;
      color: #fff;
      font-weight: normal;
      position: relative;
      bottom: -20px;
      text-transform: uppercase;
    }
    &:hover {
      @include gradientReverse;
      background-size: 100% auto;
    }
  }
  .kiermasze {
    padding-top: 37px;
    background: url("../assets/img/kiermasze.png") no-repeat top center;
    background-size: 100% auto;
  }

  .darowizny {
    padding-top: 37px;
    background: url("../assets/img/darowizny.png") no-repeat top center;
    background-size: 100% auto;
  }

  .przeprowadzki {
    padding-top: 37px;
    background: url("../assets/img/przeprowadzki.png") no-repeat top center;
    background-size: 100% auto;
  }

  .zbiorki {
    padding-top: 37px;
    background: url("../assets/img/zbiorki.png") no-repeat top center;
    background-size: 100% auto;
  }
  .overlay a {
    color: white;
    font-size: 15px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    transition: 0.3s ease-in all;
  }

  .wybrana {
    text-decoration: underline;
  }
  @media screen and (max-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .box {
      width: calc((100% - 45px) / 4);
      .kiermasze, .darowizny, .przeprowadzki, .zbiorki {
        background-size: auto 100%;
      }
    }
  }
  @media screen and (max-width: $smalltablet) {
    .box {
      width: calc((100% - 30px) / 2);
      .kiermasze, .darowizny, .przeprowadzki, .zbiorki {
        background-size: cover;
      }
    }
  }
  @media screen and (max-width: $mobile) {
    .box {
      width: 100%;
      .kiermasze, .darowizny, .przeprowadzki, .zbiorki {
        background-size: 100% auto;
      }
    }
  }
}

.right-content {
  // width: 222px;
  margin-top: 5px;
}

.logotype {
  margin: 15px 0;
  display: flex;
  @media screen and (max-width: $tablet) {
      justify-content: flex-end;
  }
  @media screen and (max-width: $smalltablet) {
      margin: 0px auto;
  }
}

.right-links {
  .tax {
    margin: 0 0 10px 0;
    border-radius: 10px;
    padding: 20px;
    background: $link;
    display: block;
    color: white;
    transition: 0.3s ease-in all;
    &:hover {
      background: $mainLight;
      color: $text;
    }
  }
  .links-bottom {
    margin-top: 20px;
    h2 {
      font-family: $fontMontserrat;
      color: $link;
      margin: 0;
      padding: 0 0 17px;
      font-size: 15px;
      text-transform: uppercase;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    ul li {
      margin-bottom: 10px;
      border-radius: 10px;
      background: #e5e7d0;
      transition: 0.3s ease-in all;
      &:hover {
        background: $mainLight;
      }
    }
    a {
      display: block;
      font-family: $fontMontserrat;
      color: $link;
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      transition: 0.3s ease-in all;
      padding: 10px;
      &.dotpay {
        padding: 0;
        transition: 0.3s ease-in all;
        display: inline-block;
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: $tablet) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    .links-top {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .tax {
        height: fit-content;
      }
    }
    .links-bottom {
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      h2 {
        width: 100%;
        padding-bottom: 0;
      }
      img {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width: $smalltablet) {
    grid-template-columns: 100%;
    .links-top {
      gap: 15px;
    }
    .links-bottom {
      ul {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: $mobile) {
      .links-bottom {
        ul {
          width: 100%;
        }
      }
  }
}
</style>
