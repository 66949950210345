<template>
    <div>
        <Slider></Slider>
        <section class="main-content" v-if="pageData">
            <header class="header">
                <h1 style="display: none" class="content__header">{{ pageData.title }}</h1>
            </header>
            <div v-html="pageData.content"></div>
            <Gallery v-if="pageData.gallery" :galleryItems="zmodyfikowaneDaneDoGalerii" />
            <!-- lewa strona propsa to jest nowy props, który trzeba zapowiedzieć w dziecku. A prawa strona to są jakieś dane, które są w TYM komponencie (patrz w tym przykładzie na computed) -->
            <!-- metody odpalam sama w mounted albo w innej metodzie, a computed odpala się samo wtedy, kiedy jedna z jego części zmieniła się w aplikacji (bo przelicza) np. pobrałam pageData z serwera -->
        </section>
    </div>
</template>

<script>
import axios from "axios";
import Slider from "@/components/Slider";
import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            slider: null,
        };
    },
    computed: {
        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.pageData.gallery;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    path: `/upload/galeria/${item.zdjecie_sciezka}`,
                    pathMin: `/upload/galeria/_min/${item.zdjecie_sciezka}`,
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
    },
    components: {
        Slider: Slider,
        Gallery: Gallery,
    },
    name: "Glowna",
    props: ["pageData"],
    methods: {
        getSlider() {
            axios.get("/api/slider/slider.php").then((response) => {
                // axios.get("https://emaus.zdzislowicz.dev/api/slider/slider.php").then((response) => {
                // axios.get("http://localhost:3000/slider").then((response) => {
                this.slider = response.data;
            }).catch(e => new Error(e) );
        },
    },
    mounted() {
        this.getSlider();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

$whiteFE: #fefefe;
$whiteF5: #f5f5f5;
$gray: #cecece;
$colorCream: #f5f7da;
$green: rgba(171, 225, 98, 1);
$greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;

.slider {
    position: relative;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 3;
        padding: 15px;
        background: $whiteF5;
        position: relative;
        h2 {
            color: $whiteFE;
            text-align: center;
            max-width: 750px;
            line-height: 1.2;
            font-weight: 700;
        }
        @media screen and (max-width: $tablet) {
            h2 {
                text-align: left;
            }
        }
    }
}
</style>
