<template>
    <section class="form kontakt" data-space="0">
        <span class="komunikat potwierdzenie" :class="{ active: potwierdzenie }" style="display: none" ref="formKomunikat">
            {{ komunikat }}
        </span>
        <div class="flex-box">
            <form class="" action="#" method="post" :class="{ hide: potwierdzenie }">
                <fieldset>
                    <legend>Formularz</legend>
                    <div class="field">
                        <label for="kontakt_imie">Nadawca</label>
                        <input ref="error_imie" v-on:input="change" required v-model="kontakt_imie" type="text" id="kontakt_imie" class="kontakt_imie" name="kontakt[kontakt_imie]" />
                        <p class="blad" v-if="formErrors[0].error_imie.length">
                            {{ formErrors[0].error_imie }}
                        </p>
                    </div>
                    <div class="field">
                        <label for="kontakt_telefon">Telefon</label>
                        <input ref="error_telefon" v-on:input="change" required v-model="kontakt_telefon" type="tel" name="kontakt[kontakt_telefon]" id="kontakt_telefon" class="kontakt_telefon" />
                        <p class="blad" v-if="formErrors[0].error_telefon">
                            {{ formErrors[0].error_telefon }}
                        </p>
                    </div>
                    <div class="field">
                        <label for="kontakt_email">E-mail</label>
                        <input ref="error_email" v-on:input="change" required v-model="kontakt_email" type="email" name="kontakt[kontakt_email]" id="kontakt_email" class="kontakt_email" />
                        <p class="blad" v-if="formErrors[0].error_email.length">
                            {{ formErrors[0].error_email }}
                        </p>
                    </div>
                    <div class="field">
                        <label for="kontakt_tresc">Wiadomość</label>
                        <textarea ref="error_tresc" v-on:input="change" required v-model="kontakt_tresc" id="kontakt_tresc" class="kontakt_tresc" name="kontakt[kontakt_tresc]"></textarea>
                        <p class="blad" v-if="formErrors[0].error_tresc.length">
                            {{ formErrors[0].error_tresc }}
                        </p>
                    </div>
                    <div class="field agreement">
                        <div
                            class="choice"
                            @click="
                                kontakt_zgoda = !kontakt_zgoda;
                                change();
                            "
                        >
                            <div class="checkbox">
                                <button ref="error_zgoda" class="donotfade check zgoda1" :class="{ active: kontakt_zgoda }" type="button" title="Kliknij, by wyrazić zgodę na przetwarzanie danych"></button>
                            </div>
                            <span class="zgoda"
                                >Wyrażam zgodę na przetwarzanie moich danych osobowych, zgodnie z treścią ustawy z dn. 29&nbsp;sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 1997 r. Nr 133 poz. 883) w celu własciwej realizacji zapytania.
                                Administratorem danych osobowych jest Stowarzyszenie Emaus-Rzeszów, ul.Batorego 22, 35-005 Rzeszów. Dane osobowe będą przetwarzane w celu właściwej realizacji odpowiedzi na zapytanie.</span
                            >
                            <p class="blad" v-if="formErrors[0].error_zgoda">
                                {{ formErrors[0].error_zgoda }}
                            </p>
                        </div>
                    </div>
                    <button type="button" class="send" :disabled="sent" @click="checkForm">Wyślij wiadomość</button>
                </fieldset>
            </form>
        </div>
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            sent: false,
            kontakt_imie: "",
            kontakt_telefon: "",
            kontakt_email: "",
            kontakt_tresc: "",
            kontakt_zgoda: "",
            komunikat: "",
            potwierdzenie: false,
            formErrors: [
                {
                    error_imie: "",
                    error_telefon: "",
                    error_email: "",
                    error_tresc: "",
                    error_zgoda: "",
                },
            ],
        };
    },
    name: "Form",
    methods: {
        change() {
            if (this.kontakt_imie.length === 0) {
            } else {
                this.formErrors[0].error_imie = "";
            }
            if (this.kontakt_telefon.length === 0) {
            } else {
                this.formErrors[0].error_telefon = "";
            }
            if (this.kontakt_email.length === 0) {
            } else {
                this.formErrors[0].error_email = "";
            }
            if (this.kontakt_tresc.length === 0) {
            } else {
                this.formErrors[0].error_tresc = "";
            }
            if (this.kontakt_zgoda === false) {
            } else {
                this.formErrors[0].error_zgoda = "";
            }
        },
        checkForm: function (e) {
            e.preventDefault();
            axios
                .post("/api/form/form.php", this.pobierzDaneFormularza)
                .then((response) => {
                    var vm = this;

                    this.potwierdzenie = JSON.parse(JSON.stringify(response.data.potwierdzenie));
                    if (this.potwierdzenie === true) {
                        this.sent = true;
                        this.goto('formKomunikat');
                        console.log("potwierdzenie jest true");
                        this.komunikat = JSON.parse(JSON.stringify(response.data.komunikat));
                    } else {
                        console.log("potwierdzenie jest false");
                        var errors = JSON.parse(JSON.stringify(response.data.error));

                        var errorFocus = Object.keys(errors)[0];
                        this.$refs[errorFocus].focus();

                        Object.keys(errors).map(function (key) {
                            vm.formErrors[0][key] = errors[key];
                        });
                    }
                })
                .catch((error) => {});
        },
        goto(refName) {
            var element = this.$refs[refName];
            console.log(element);
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },
        // submitForm() {},
    },
    computed: {
        pobierzDaneFormularza() {
            const daneZFormularza = {
                imie: this.kontakt_imie,
                telefon: this.kontakt_telefon,
                email: this.kontakt_email,
                tresc: this.kontakt_tresc,
                zgoda: this.kontakt_zgoda,
            };
            return daneZFormularza;
        },
    },
    mounted() {
        //empty
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

@mixin gradient {
    background-color: rgba(171, 225, 98, 1);
    background-image: linear-gradient(0deg, rgba(171, 225, 98, 1) 0%, rgba(137, 186, 58, 1) 100%);
}
@mixin gradientReverse {
    background-color: rgba(137, 186, 58, 1);
    background-image: linear-gradient(0deg, rgba(137, 186, 58, 1) 0%, rgba(171, 225, 98, 1) 100%);
}

.wide {
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    header {
        display: block;
        position: relative;
        // h2 {
        //     padding-bottom: 35px;
        //     background: url(../assets/img/svg/icon-section.svg) bottom center no-repeat;
        //     background-size: 67px auto;
        //     text-align: center;
        //     text-transform: uppercase;
        //     font-weight: 700;
        //     @include fontSize33px;
        // }
    }
    p {
        text-align: center;
        &:last-of-type {
            padding-bottom: 0;
        }
    }
}

.form {
    color: $text;
    position: relative;
    // @include container;
    .container {
        text-align: center;
        @media screen and (max-width: $mobile) {
            text-align: left;
        }
    }
    .komunikat.active {
        display: block !important;
        color: $text;
        text-align: center;
        @include fontSize14px;
        height: 60px;
    }
    .hide {
        display: none !important;
    }
    .flex-box {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // column-gap: 45px;
        width: 100%;
        position: relative;

        // @media screen and (max-width: $bigtablet) {
        //     padding: 0 150px;
        // }

        // @media screen and (max-width: $tablet) {
        //     padding: 0 100px;
        // }

        fieldset {
            height: 100%;
            position: relative;
            display: grid;
            gap: 18px;
            legend {
                display: none;
            }
            label {
                margin-bottom: 8px;
                @include fontSize13px;
                // color: $whiteFE;
            }
        }
        @media screen and (max-width: $smalltablet) {
            grid-template-columns: 100%;
            row-gap: 80px;
            padding-top: 45px;
            margin: 0 auto;
            fieldset {
                gap: 30px;
            }
        }

        .field {
            width: 100%;
            position: relative;

            // &--big {

            //     @media screen and (min-width: $smalltablet) {
            //         grid-column-start: 1;
            //         grid-column-end: 2;
            //     }
            // }

            span {
                &.blad {
                    color: $mainDarker;
                    display: none;
                    padding-top: 6px;
                }
            }
            p {
                &.blad {
                    width: 100%;
                    text-align: left;
                    color: $mainDarker;
                    @include fontSize14px;
                    padding-top: 6px;
                }
            }
            textarea {
                // border: 2px solid $colorGreyDark;
                padding: 20px 14px;
                width: 100%;
                font-family: $fontMontserrat;
                resize: vertical;
                min-height: 190px;
                @include fontSize16px;
                transition: 0.3s ease all;
                overflow: auto;

                &::placeholder {
                    font-family: $fontMontserrat;
                    color: #808080;
                }
                // @media screen and (max-width: $tablet) {
                //     min-height: calc(100% - 34px);
                // }
                @media screen and (max-width: $smalltablet) {
                    padding: 20px 14px;
                    grid-column-end: 1;
                    min-height: 270px;
                    max-height: 70vh;
                }
                // @media screen and (max-width: $mobile) {
                //     max-width: 248px;
                // }
            }

            input,
            textarea {
                margin-top: 6px;
                background: $whiteFE;
                border: 1px solid $whiteFE;
                padding: 10px 24px;
                width: 100%;
                font-family: $fontMontserrat;
                @include fontSize16px;
                color: $text;

                &::placeholder {
                    text-transform: uppercase;
                    font-family: $fontMontserrat;
                    color: $text;
                    font-weight: 400;
                }

                // @media screen and (max-width: $mobile) {
                //     line-height: 40px;
                //     max-height: 50px;
                // }
            }
        }
        .form__details {
            div {
                position: relative;
                @include fontSize11px;
                margin-bottom: 75px;
                min-height: 82px;
                text-align: left;
                padding-left: 115px;
                p {
                    text-align: left;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                header {
                    h3 {
                        text-transform: uppercase;
                        font-weight: 700;
                        @include fontSize14px;
                        padding-bottom: 20px;
                        position: relative;
                        top: -4px;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 82px;
                    height: 82px;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 1px solid $whiteFE;
                    border-radius: 50%;
                }
            }
            a {
                color: $whiteFE;
                display: block;
                &:hover {
                    color: $mainDarker;
                }
            }
            @media screen and (max-width: $smalltablet) {
                display: flex;
                flex-direction: column;
                div {
                    width: 260px;
                    margin: 0 auto 45px;
                    padding-left: 95px;
                    &::before {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
            @media screen and (max-width: $mobile) {
                div {
                    width: 100%;
                }
            }
        }
        .agreement {
            // grid-column: 1/3;
            @include fontSize13px;
            // @media screen and (max-width: $smalltablet) {
            //     grid-column-end: 1;
            // }
        }
        .choice {
            display: flex;
            flex-wrap: wrap;
            // cursor: pointer;
            .checkbox {
                // cursor: pointer;
                button,
                a {
                    background: $whiteFE;
                    display: block;
                    height: 30px;
                    width: 30px;
                    transition: all 0.3s;
                    position: relative;
                    margin-right: 20px;

                    &::after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        content: "";
                        background: url(../assets/img/svg/icon-checked.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 55% auto;
                        transition: 0.3s ease all;
                        opacity: 0;
                    }
                }
                .active {
                    &::after {
                        opacity: 1;
                    }
                }
                input {
                    display: none;
                }
            }
            label,
            span {
                padding: 0;
                cursor: pointer;
                display: block;
                text-align: left;
                @include fontSize11px;
                width: calc(100% - 50px);
                &.zgoda {
                    margin-bottom: 0;
                }

                a {
                    text-decoration: underline;
                    z-index: 10000;
                    position: relative;
                    // color: $colorGray;
                }
            }
        }

        input[type="submit"],
        .send {
            padding: 7px 19px;
            margin: 0 auto;
            @include fontSize16px;
            @include gradientReverse;
            color: $whiteFE;
            border: 1px solid $whiteFE;
            text-align: center;
            transition: 0.3s all ease-in;
            border-radius: 5px;
            transition: 0.3s ease-in all;
            @media screen and (max-width: $mobile) {
                margin-bottom: 0;
                width: 100%;
                max-width: unset;
                // text-align: center;
            }

            &:hover {
                @include gradient;
            }
        }
    }
}
</style>
