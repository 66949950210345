/*
         _       _       _                  _                       _
 ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
|_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
 / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
/___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                            |_|

CONTACT FORM
www.zdzislowicz.pl
biuro@zdzislowicz.pl

all rights reserved
*/
$(document).ready(function() {
    gogogo = false;

    $(".formularz-kontakt" ).submit(function(e) {
        if(!gogogo)
        {
            formularz = $(this);

            $(formularz.find('input[type=submit]')).attr('disabled', 'disabled');

            var kontakt = {};
            $(formularz.find('input[name^="kontakt"]')).each(function() {
                if($(this).is(':checkbox'))
                {
                    if($(this).is(':checked'))
                    {
                        kontakt[$(this).attr('class')] = "1";
                    }
                    else
                    {
                        kontakt[$(this).attr('class')] = '0';
                    }
                }
                else
                {                
                    kontakt[$(this).attr('class')] = $(this).val();
                }
            });
            $(formularz.find('textarea[name^="kontakt"]')).each(function() {
                kontakt[$(this).attr('class')] = $(this).val();
            });
            $(formularz.find('select[name^="kontakt"]')).each(function() {
                kontakt[$(this).attr('class')] = $(this).val();
            });

            kontakt = JSON.stringify(kontakt);

            $.ajax( {
                url: '/ajax/formularz-kontaktowy.php',
                dataType: 'json',
                type: 'POST',
                data: "kontakt=" + kontakt,
                success: function(data, status) {
                    bledy = data.bledy;
                    potwierdzenie = data.potwierdzenie;
                    komunikat = data.komunikat;

                    var JSONArray = $.parseJSON(bledy);

                    caret = -1;
                    caret_field = "";
                    $.each(JSONArray, function(index, value) {

                        if(caret == -1) {
                            caret++;
                            caret_field = index;

                            if(formularz.find('.' + index).parent().hasClass('check'))
                            {
                                $('html, body').animate({
                                    scrollTop: (formularz.find('.' + index).parent().offset().top - 120)
                                }, 250);
                            }
                            else
                            {
                                $('html, body').animate({
                                    scrollTop: (formularz.find('.' + index).offset().top - 120)
                                }, 250);
                            }

                            setTimeout(function() {
                                formularz.find('.' + index).focus();
                            }, 250);
                        }
                        if(formularz.find('.' + index).parent().hasClass('checkbox'))
                        {
                            // formularz.find('.' + index).parent().addClass('error');
                            formularz.find('.' + index).parent().parent().find('.blad').css('display', 'block');
                            formularz.find('.' + index).parent().parent().find('.blad').html(value);                        
                        }
                        else
                        {
                            formularz.find('.' + index).parent().find('.blad').css('display', 'block');
                            formularz.find('.' + index).parent().find('.blad').html(value);
                        }
                    });

                    if(potwierdzenie == 1)
                    {
                        /*
                        formularz.css('display', 'none');
                        formularz.parent().find('.potwierdzenie').css('display', 'block');

                        // console.log(formularz.parent().find('.potwierdzenie').offset().top);

                        $('html, body').animate({
                            scrollTop: (formularz.parent().offset().top)
                        }, 500);

                        if (typeof ga === 'function') {
                            // ga('send', 'event', 'contactform', 'click');
                        }
                        */
                        if(potwierdzenie == 1)
                        {
                            gogogo = true;
                            $('.formularz-kontakt').submit();
                        }                       
                    }

                    $(formularz.find('input[type=submit]')).removeAttr('disabled');
                },
                error: function() {
                    $(formularz.find('input[type=submit]')).removeAttr('disabled');
                }
            });

            e.preventDefault();
            e.stopPropagation();
            return;
        }
    }
    );

    $(".formularz-kontakt input").on('keyup click contextmenu', function(e) {
        switch (e.which) {
            case 13:
                break;
            default:
            {
                $(this).parent().find('.blad').css('display', 'none');
                $(this).parent().find('.blad').html('');    
            }
                // if($(this).parent().hasClass('error'))
                //     $(this).parent().removeClass('error');    
                // else
                //     $(this).removeClass('error');
            break;
        }
    });

    $(".formularz-kontakt select").on('keyup click contextmenu', function(e) {
        switch (e.which) {
          case 13:
              break;
          default:
              // $(this).removeClass('error');
              {
                $(this).parent().find('.blad').css('display', 'none');
                $(this).parent().find('.blad').html('');    
              }
          break;
        }
    });

    $(".formularz-kontakt textarea").on('keyup click contextmenu', function(e) {
        switch (e.which) {
          case 13:
              break;
          default:
              // $(this).removeClass('error');
                {
                    $(this).parent().find('.blad').css('display', 'none');
                    $(this).parent().find('.blad').html('');                  
                }
          break;
        }
    }); 
    
    choiceStop = false;
    $('.formularz-kontakt .choice label a').on('click', function(e) {       
        choiceStop = true;
    });

    $('.formularz-kontakt .choice').on('click', function(e) {
         
        if(!choiceStop)      
        {
            $(this).find('a').removeClass('error');
            
            if($(this).find('a').hasClass('active')) {
                $(this).find('a').removeClass('active');
                $(this).find('input').prop('checked', false);                
                // $(this).find('input').removeClass('error');
                $(this).find('input').val("0");  
            }
            else {
                $(this).find('a').addClass('active');
                $(this).find('input').prop('checked', true);                                
                $(this).find('input').val("1");  
                $(this).find('input').removeClass('error');
            }

            $(this).find('.blad').css('display', 'none');
            $(this).find('.blad').html('');            

            e.preventDefault();
            e.stopPropagation();                                    
        }
        choiceStop = false;          
    });
});