/*
         _       _       _                  _                       _
 ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
|_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
 / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
/___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                            |_|

LOCATION HASH FROM URL
www.zdzislowicz.pl
biuro@zdzislowicz.pl

all rights reserved
*/
var target = location.hash;
target = target.replace('#', '');
window.location.hash = "";

var lastScrollTop = 0;
var scrollBlock = false;

// other url
$(document).ready(function() {
    // if (target) {
    //   $('html,body').animate({scrollTop: $('.' + target).offset().top}, 500);
    // }
});

// same url
$(document).on('click', '.navbar ul li a, .navbarMobile ul li a, .gohash', function(e) {

    hash = $(this).attr('href').replace(/^.*?(#|$)/,'');
    adres = $(this).attr('href');
    adres = adres.replace(strona_adres_pelny, '');
    adres = adres.replace('/', '');
    adres = adres.replace('#' + hash, '');

    adresAktualny = window.location.href;
    adresAktualny = adresAktualny.replace(strona_adres_pelny, '');
    adresAktualny = adresAktualny.replace('#' + hash, '');
    adresAktualny = adresAktualny.replace('#', '');

    // inny adres, przekierowanie
    if(adres != adresAktualny)
    {
        /*
        goto = "/" + adres + "/";
        if(hash != "")
            goto = goto + '#' + hash;

        goto = goto.replace("//", "/");

        window.location.href = goto;
        return;
        */
    }
    else if(hash != "")
    {
        e.preventDefault();
        e.stopPropagation();
        $('html,body').animate({scrollTop: $("." + hash).offset().top + 100}, 500);
    }
});

/*
$(window).scroll(function(){
    var top = 0;
    top = $(document).scrollTop();
    var wybor = "";

    $('main > section').each(function() {
        space = $(this).attr('data-space');

        if(($(this).offset().top-top-space) < 0)
        {
            wybor = $(this).attr('id');
        }
    });

    // check if is last
    if($(window).scrollTop() + $(window).height() == $(document).height()) {
        wybor = $('body > section').last().attr('id');
    }

    $('.navbar ul li a').removeClass('active');
    $('.navbar ul li .' + wybor).addClass('active');
});
*/