/*
         _       _       _                  _                       _
 ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
|_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
 / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
/___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                            |_|

BURGER MENU ADVANCED
www.zdzislowicz.pl
biuro@zdzislowicz.pl

all rights reserved
*/

const burger = document.querySelector('.burgermenu');

$(document).ready(function() {
    var burgermenuWindowWidth = $( window ).width();

    $(window).resize(function() {
        if( burgermenuWindowWidth != $( window ).width() ) {
            burgermenuHide();
        }
        burgermenuWindowWidth = $( window ).width();
    });

    $(window).on('orientationchange', function() {
        burgermenuHide();
    });

    $('.burgermenu').on('click', function()
    {
        if(
            !$(this).hasClass('active')
        )
        {
            // pokaz
            burgermenuShow();
            
        }
        else
        {
            burgermenuHide();
        }
    });

    $(document).on('click', '.navbarMobile .close button', function() {
        burgermenuHide();
    });

    // close when hash detected
    // $(document).on('click', '.navbarMobile a', function() {
    //     burgermenuHide();
    // });

    function burgermenuShow() {
        $('body').append(
        '<div class="navbarMobile">' +
            '<div class="top">' + 
                '<div class="close"><button title="Zamknij menu" tabindex="1"></button></div>' +
            '</div>' +
            '<div class="container">' +    
                $('.navbar').html() +
            '</div>' +
        '</div>'
        );

        $('.navbarMobile .container').css('height', $('.navbarMobile').find('ul').height() + 200 + 'px');
        $('.navbarMobile ul').first().addClass('show');
        $('body .navbarMobile .show li button').attr('tabindex', '1').addClass('button');

        $('body').removeClass('bodyscroll');
        $('body').addClass('bodynoscroll');

        $('body a').attr('tabindex', '-1');
        $('body .navbarMobile a').attr('tabindex', '1');  
        $('body .burgermenu').focus();     
    }

    function burgermenuHide() {
        $('.burgermenu').removeClass('active');
        $('.navbarMobile').remove();

        $('body').removeClass('bodynoscroll');
        $('body').addClass('bodyscroll');        

        $('body a').attr('tabindex', '1');
        $('body .navbarMobile a').attr('tabindex', '-1');
        $('body .burgermenu').focus();     
    }

    $('body').on('click', '.navbarMobile .button', function(e) {

        e.preventDefault();
        e.stopPropagation();        

        $(this).parent().parent().removeClass('show');
        $(this).next().addClass('show');
        $('.navbarMobile .container').css('height', $('.navbarMobile ul.show').height() + 200 + 'px');

        back = $(this).prev().text();
 
        $(this).next().prepend('<li class="back"><button class="button" tabindex="1">' + back + '</button></li>');
    });

    $('body').on('click', '.navbarMobile .back .button', function(e) {

        back = $(this).parent().parent().parent().parent().prev().prev().text();

        $('.navbarMobile ul').removeClass('show');
        $(this).parent().parent().parent().parent().addClass('show');
        $('.navbarMobile .back').remove();

        if(back != "")
        {
            $('.navbarMobile .container').css('height', $('.navbarMobile ul.show').height() + 200 + 'px');
            $('.navbarMobile ul.show').prepend('<li class="back"><button class="button">' + back + '</button></li>');
        }
        else
        {
            $('.navbarMobile .container').css('height', $('.navbarMobile ul.show').height() + 200 + 'px');
        }
    });
});

/*
         _       _       _                  _                       _
 ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
|_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
 / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
/___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                            |_|

BURGER MENU LEFT SIDE
www.zdzislowicz.pl
biuro@zdzislowicz.pl

all rights reserved
*/

// const burgerLeft = document.querySelector('.burgermenuLeft');

// $(document).ready(function() {
//     var burgermenuWindowWidth = $( window ).width();

//     $(window).resize(function() {
//         if( burgermenuWindowWidth != $( window ).width() ) {
//             burgermenuLeftHide();
//         }
//         burgermenuWindowWidth = $( window ).width();
//     });

//     $(window).on('orientationchange', function() {
//         burgermenuLeftHide();
//     });

//     $('.burgermenuLeft').on('click', function()
//     {
//         if(
//             !$(this).hasClass('active')
//         )
//         {
//             // pokaz
//             burgermenuLeftShow();
            
//         }
//         else
//         {
//             burgermenuLeftHide();
//         }
//     });

//     $(document).on('click', '.fixed-menu--opened .menu-close', function() {
//         burgermenuLeftHide();
//     });


//     function burgermenuLeftShow() {
//         document.querySelector('.fixed-menu--opened').classList.add('active');
//         document.querySelector('.burgermenuLeft').style.display = "none";
//     }

//     function burgermenuLeftHide() {
//         document.querySelector('.fixed-menu--opened').classList.remove('active');
//         document.querySelector('.burgermenuLeft').style.display = "block";
//     }

//     $('body').on('click', '.navbarMobile .button', function(e) {

//         e.preventDefault();
//         e.stopPropagation();        

//         $(this).parent().parent().removeClass('show');
//         $(this).next().addClass('show');
//         $('.navbarMobile .container').css('height', $('.navbarMobile ul.show').height() + 200 + 'px');

//         back = $(this).prev().text();
 
//         $(this).next().prepend('<li class="back"><button class="button" tabindex="1">' + back + '</button></li>');
//     });

//     $('body').on('click', '.navbarMobile .back .button', function(e) {

//         back = $(this).parent().parent().parent().parent().prev().prev().text();

//         $('.navbarMobile ul').removeClass('show');
//         $(this).parent().parent().parent().parent().addClass('show');
//         $('.navbarMobile .back').remove();

//         if(back != "")
//         {
//             $('.navbarMobile .container').css('height', $('.navbarMobile ul.show').height() + 200 + 'px');
//             $('.navbarMobile ul.show').prepend('<li class="back"><button class="button">' + back + '</button></li>');
//         }
//         else
//         {
//             $('.navbarMobile .container').css('height', $('.navbarMobile ul.show').height() + 200 + 'px');
//         }
//     });
// });


/*
         _       _       _                  _                       _
 ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
|_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
 / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
/___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                            |_|

HOVER/FOCUS MENU
www.zdzislowicz.pl
biuro@zdzislowicz.pl

all rights reserved
*/

function zMenuClear(ul, li) {
    let elList = document.querySelectorAll(ul);
    elList.forEach(function(el) {
        el.classList.remove('showsub');
    });

    elList = document.querySelectorAll(li);
    elList.forEach(function(el) {
        el.classList.remove('active');
    });     
}

function zMenuLoop(el) {
    while(el.tagName == "UL")
    {
        if(el.parentNode.tagName == "LI") {
            el.classList.add("showsub");
            el.parentNode.classList.add('active');
        }
        el = el.parentNode.parentNode;
    }
}

function zMenu(navbar) {
    var buttonList = document.querySelectorAll(navbar + ' button');
    buttonList.forEach(function(item) {
        item.addEventListener('click', function(e) {
            ul = this.nextElementSibling;
            li = this.parentNode;
            if(ul.className == "showsub")
            {
                ul.classList.remove('showsub');
                li.classList.remove('active');
            }
            else
            {
                zMenuClear(navbar + ' ul.showsub', navbar + ' li.active');
                ul.classList.add('showsub');
                li.classList.add('active'); 
                
                parent = li.parentNode;
                zMenuLoop(parent);
            }
        });
    });

    var aItem = document.querySelectorAll(navbar + ' a, ' + navbar + ' button');
    aItem.forEach(function(item) {
        item.onblur = blurElem;
        function blurElem(e) {
            zMenuClear(navbar + ' ul.showsub', navbar + ' li.active');
            if(e.relatedTarget !== undefined && e.relatedTarget !== null)
            {
                parent = e.relatedTarget.parentNode.parentNode;
                zMenuLoop(parent);        
            }
            e.stopPropagation();
        }
    });


    var aList = document.querySelectorAll(navbar + ' li a');
    aList.forEach(function(item) {
        item.onfocus = showElem;
        function showElem(e) {
            if(item.parentNode.parentNode.tagName == "UL")
                item.parentNode.parentNode.classList.add("showsub");
            e.stopPropagation();
        }
    });

    var liList = document.querySelectorAll(navbar + ' li');
    liList.forEach(function(item) {
        item.onmouseover = showElem;
        item.onmouseout = hideElem;
        function showElem(e) {
            zMenuClear(navbar + ' ul.showsub', navbar + ' li.active');

            // if(item.querySelector('ul') !== undefined && item.querySelector('ul') !== null)
            //     item.querySelector('ul').classList.add('showsub');            

            item.classList.add("active");
                        
            parent = this.parentNode;            
            if(item.querySelector('ul') !== undefined && item.querySelector('ul') !== null)
                item.querySelector('ul').classList.add('showsub');

            zMenuLoop(parent);
            e.stopPropagation();
        }
        function hideElem() {
            item.classList.remove('active');
            if(item.querySelector('ul') !== undefined && item.querySelector('ul') !== null) {
                item.querySelector('ul').classList.remove('showsub');
            }
        }
    });
}

var startMenu = document.querySelectorAll('.zMenu');
startMenu.forEach(function(el) {
    el.classList.remove("zMenu");
    el.className;
    var navbar = el.className;
    zMenu('.' + navbar);
});
