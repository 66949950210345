<template>
    <section class="main-content" v-if="pageData">
        <header><h2 class="title fade fadein">{{ pageData.title }}</h2></header>
        <div class="grid-2">
            <div class="left-side">
                <div class="contact-address fade fadein" v-html="pageData.content"></div>
                <iframe class="fade fadein" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.pl/maps?f=q&amp;source=s_q&amp;hl=pl&amp;geocode=&amp;q=emaus+rzesz%C3%B3w&amp;aq=&amp;sll=49.910071,22.344993&amp;sspn=3.060089,8.453979&amp;ie=UTF8&amp;hq=emaus+rzesz%C3%B3w&amp;hnear=&amp;t=m&amp;cid=6783524674090120071&amp;ll=50.043581,22.011452&amp;spn=0.028663,0.05476&amp;z=13&amp;output=embed"></iframe>
            </div>
            <Form></Form>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Form from "@/components/Form";

export default {
    data() {
        return {
            contact: [],
        }
    },
    name: 'Contact',
    components: {
        Form: Form,
    },
    props: ['pageData'],
    methods: {
        getContact() {
            axios.get("/api/contact/contact.php").then((response) => {
                // axios.get("https://emaus.zdzislowicz.dev/api/contact/contact.php").then((response) => {
                // axios.get("http://localhost:3000/contact").then((response) => {
                this.contact = response.data;
            })
            .catch((error) => {
              // error.response.status Check status code
              console.log(error)
          });
        }
    },
    mounted() {
        this.getContact()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.main-content {
    margin-top: 30px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 11px 6px rgb(175, 175, 175);
    -moz-box-shadow: 0px 0px 11px 6px rgba(175, 175, 175, 0.3);
    box-shadow: 0px 0px 11px 6px rgba(175, 175, 175, 0.3);
    border-radius: 5px;
}

.grid-2 {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 30px;
    .left-side {
        .contact-address {
            margin-bottom: 30px;
        }
    }
    iframe {
        width: 100%;
        min-height: 350px;
    }
    @media screen and (max-width: $smalltablet) {
        grid-template-columns: 100%;
    }
}


</style>
