<template>
    <aside class="popupBox" v-if="popupActive && !popupActivate" id="various5">
        <div class="container">
            <button class="popupClose" type="button" @click="closePopup()" title="Zamknij komunikat">x</button>
            <img src="@/assets/img/3a.png" title="Emaus" />
        </div>
    </aside>
</template>

<script>
export default {
    data() {
        return {
            popupActive: true,
            popupActivate: this.$cookie.get("popup"),
        };
    },
    name: "Popup",
    // props: {
    //   msg: String,
    // },
    methods: {
        closePopup() {
            
            this.popupActive = false;
            console.log("popup");
            console.log(this.popupActive);
            // $("#various5").fancybox({
            //     width: 890,
            //     height: 313,
            //     autoScale: false,
            //     transitionIn: "none",
            //     transitionOut: "none",
            //     type: "image",
            //     // onComplete: function () {
            //     //     setTimeout(function () {
            //     //         $.fancybox.close();
            //     //     }, 3000); // 3000 = 3 secs
            //     // },
            // });
            
        },
        selfClosePopup() {
          this.$cookie.set("popup", "EmausRzeszow", 1);
          setTimeout(function () {
                this.popupActive = false;
            }, 3000);
        }
    },
    mounted() {
      this.selfClosePopup();
    },
    updated() {
        this.closePopup();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
$colorCream: #f5f7da;
$whiteFE: #fefefe;
$mainLight: #abd138;
$mainDarker: #94b520;

#app {
    .popupBox {
        position: fixed;
        width: 100vw;
        height: 100%;
        min-height: 100vh;
        top: 0;
        bottom: 0;
        //left: 0;
        z-index: 9999;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 40px 25px;
        .container {
          padding-top: 100px;
          max-width: 890px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .popupClose {
          font-size: 40px;
          font-weight: 700;
          align-self: flex-end;
          color: #666;
        }
          img {
              max-width: 100%;
              height: auto;
          }
    }
}
</style>
