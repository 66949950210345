<template>
    <section class="main-content" v-if="pageData" :class="newsArticle">
        <div class="singleNews">
            <div class="singleNews__picture fade fadein">
                <picture><img v-if="pageData.imagePathMin" :src="pageData.imagePathMin" alt=""/>
                <img v-else src="@/assets/img/default.jpg" alt=""/>
                <!-- <img v-else :src="`/${pageData.imagePath}`" alt=""/> -->
                <!-- <picture class="pic"><img src="@/assets/img/default.jpg" alt=""/> -->
                </picture>

            </div>
            <div class="singleNews__description">
                <header class="header fade fadein"> <h1>{{ pageData.title }}</h1></header>
                <span class="date"> {{ pageData.date }} </span>
            </div>
            <div class="singleNews__content fade fadein" v-html="pageData.content">

            </div>
        </div>
        <Gallery v-if="pageData.gallery" :galleryItems="zmodyfikowaneDaneDoGalerii" />
    </section>
</template>

<script>
import axios from "axios";
import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            newsItem: null,
            newsArticle: 'newsArticle'
        }
    },
    name: 'Wpis',
    components: {
        Gallery: Gallery
    },
    computed: {
        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.pageData.gallery;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    path: `/upload/strony/${item.zdjecie_sciezka}`,
                    pathMin: `/upload/strony/_min/${item.zdjecie_sciezka}`,
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
    },
    props: ['pageData'],
    methods: {
        getNewsItem() {
            axios.get("/api/newsList/newsList.php").then((response) => {
                // axios.get("https://emaus.zdzislowicz.dev/api/newsList/newsList.php").then((response) => {
                // axios.get("http://localhost:3000/newsItem").then((response) => {
                this.newsItem = response.data;
           }).catch(e => new Error(e) );
        },
    },
    mounted() {
        this.getNewsItem()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";



.main-content {
    &.news {
        display: grid;
        row-gap: 30px;
    }
    &.newsArticle {
        row-gap: 0;
    }
}

.singleNews {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
    position: relative;
    &:hover {
        cursor: default;
        .pic {
            opacity: 1;
        }
    }
    
    @media screen and (max-width: $tablet) {
        // grid-template-columns: 100%;
        grid-template-rows: auto auto;
        gap: 30px;

    }
    
    &__description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        color: $grey6B;
        position: relative;
        
        h1 {
            @include fontSize18px;
            text-transform: uppercase;
            color: $link;
            padding: 0;
        }
        h2 {
            @include fontSize13px;
            text-transform: uppercase;
            color: $link;
            padding: 0;
        }
        .date {
            @include fontSize12px;
            color: $link;
            display: block;
            padding-left: 25px;
            background: url("../assets/img/svg/icon-calendar.svg") no-repeat left;
        }
        p {
            color: $grey6B;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0;
            @media screen and (max-width: $smalltablet) {
                -webkit-line-clamp: 2;
            }
        }
        .readMore {
            position: absolute;
            bottom: 0;
            color: $link;
            background: url("../assets/img/svg/arrow-right.svg") no-repeat right center;
            background-size: 50px auto;
            width: fit-content;
            padding-right: 35px;
        }
    }
    
    &__picture {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
            // border: 1px solid $border;
            width: 180px;
            height: auto;
        }
        @media screen and (max-width: $tablet) {
            align-items: flex-start;
        }
    }
    &__content {
        display: block;
        width: 100%;
        grid-column: 1/3;
    }
    &__header {
        @include fontSize14px;
        font-weight: 700;
        padding: 0;
    }
    // &__p {
    //     text-overflow: ellipsis;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 4;
    //     -webkit-box-orient: vertical;
    //     overflow: hidden;
    //     padding: 0;
    //     @media screen and (max-width: $smalltablet) {
    //         -webkit-line-clamp: 3;
    //     }
    // }
    // &__more {
    //     background-size: 14px auto;
    //     padding-left: 25px;
    //     transition: 0.3s ease-in color;
    //     @include fontSize12px;
    //     font-weight: 700;
    // }
    @media screen and (max-width: $smalltablet) {
        &__description {
            .readMore {
                position: relative;
            }
        }
    }
    @media screen and (max-width: $mobile) {
        grid-template-columns: 100%;
        &__content {
            grid-column: 1;
        }
    }
}

</style>
