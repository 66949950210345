<template>
    <div v-if="galleryItems" class="gallery galleryPopup">
        <ul>
            <li v-for="item in galleryItems" :key="item.path" class="fade fadein">
                <a :href="`${item.path}`" title="">
                    <picture>
                        <img :src="`${item.pathMin}`" alt="" />
                    </picture>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import "magnific-popup";
import $ from "jquery";

export default {
    name: "Gallery",
    props: ["galleryItems"],
    methods: {
        esMagnifique() {
            $(document).ready(function () {
                $(".galleryPopupSingle").magnificPopup({
                    delegate: "a",
                    type: "image",
                    gallery: {
                        enabled: !0,
                    },
                });
                $(".galleryPopup").magnificPopup({
                    delegate: "a",
                    type: "image",
                    gallery: {
                        enabled: !0,
                    },
                });
                $(".videoPopup").magnificPopup({
                    delegate: "a",
                    type: "iframe",
                    gallery: {
                        enabled: !0,
                    },
                });
            });
        },
    },
    mounted() {
        this.esMagnifique();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

$colorCream: #f5f7da;
$green: rgba(171, 225, 98, 1);
$greenDarker: rgba(137, 186, 58, 1);
$mainLight: #abd138;
$mainDarker: #94b520;

.gallery {
    //   overflow: hidden;
    padding: 30px 0 0 0;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(5, 1fr);
        li {
            // padding: 10px 0 10px 0;
            text-align: center;
            width: 100%;
            height: auto;
            position: relative;
            // &:after {
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     width: 100%;
            //     height: 100%;
            //     background-color: rgba($mainDarker, 0.8);
            //     content: "";
            //     background-image: url(../assets/img/svg/icon-magnifier.svg);
            //     background-position: center center;
            //     background-repeat: no-repeat;
            //     background-size: 26px auto;
            //     opacity: 0;
            //     transition: 0.3s ease opacity;
            // }

            &:hover {
                cursor: pointer;
                &:after {
                    opacity: 1;
                }
            }
            a {
                display: block;
            }
            img {
                border: 1px $border solid;
                padding: 1px;
                width: 100%;
                height: auto;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        ul {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media screen and (max-width: $smalltablet) {
        ul {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media screen and (max-width: $mobile) {
        ul {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
</style>
