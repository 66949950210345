<template>
    <section class="main-content" v-if="newsList.length" :class="news">
        <header class="header">
            <h1 class="content__header">Aktualności</h1>
        </header>
        <div class="singleNews list fade fadein" v-for="(item, index) in paginatedNewsList" :key="index">
            <div class="singleNews__picture">
                <picture>
                    <img v-if="item.imagePathMin" :src="item.imagePathMin" alt="" />
                    <img v-else src="@/assets/img/default.jpg" alt="" />
                </picture>
                <!-- <picture><img src="@/assets/img/default.jpg" alt=""/></picture> -->
                <!-- <picture><img :src="item.imagePath" alt=""/></picture> -->
            </div>
            <div class="singleNews__description">
                <h2>{{ item.title }}</h2>
                <span class="date"> {{ item.date }} </span>
                <span v-html="item.shortcut"></span>
                <div class="readMore">Więcej</div>
            </div>
            <router-link :to="item.link"></router-link>
        </div>
        <!-- :page-range="pageOfItems" -->
        <paginate
            :page-count="Math.ceil(newsList.length / iloscElementowNaJednejStronie)"
            :margin-pages="1"
            :click-handler="clickCallback"
            :prev-text="'Poprzednia'"
            :next-text="'Następna'"
            :container-class="'pagination'"
            :page-class="'page-item'"
        ></paginate>
    </section>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            newsList: [],
            news: "news",
            currentPage: 1,
            iloscElementowNaJednejStronie: 6,
            // pageOfItems: 6,
            allNewsList: this.newsList,
        };
    },
    computed: {
        paginatedNewsList() {
            const startNewsIndex = (this.currentPage - 1) * this.iloscElementowNaJednejStronie;
            const endNewsIndex = this.currentPage * this.iloscElementowNaJednejStronie;
            return this.newsList.slice(startNewsIndex, endNewsIndex);
        },
    },
    name: "Aktualnosci",
    methods: {
        getNewsList() {
            axios.get("/api/newsList/newsList.php").then((response) => {
                // axios.get("https://emaus.zdzislowicz.dev/api/newsList/newsList.php").then((response) => {
                // axios.get("http://localhost:3000/newsList").then((response) => {
                this.newsList = response.data;
            }).catch(e => new Error(e) );
        },
        clickCallback(currentPage) {
            this.currentPage = currentPage;
            console.log(this.currentPage)
        },
    },
    mounted() {
        this.getNewsList();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.main-content {
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 11px 6px rgba(175, 175, 175, 0.3);
    padding: 30px;
    border-radius: 5px;
    &.news {
        display: grid;
        row-gap: 30px;
    }
}
.list {
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:hover {
        cursor: pointer;
        h2,
        p,
        .readMore {
            color: $mainLight;
        }
        picture {
            opacity: 0.8;
        }
    }
}
.singleNews {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 30px;
    position: relative;
    @media screen and (max-width: $tablet) {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        gap: 30px;
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        color: $grey6B;
        position: relative;

        h2 {
            @include fontSize13px;
            text-transform: uppercase;
            color: $link;
            padding: 0;
            transition: 0.3s ease-in all;
        }
        .date {
            @include fontSize12px;
            color: $link;
            display: block;
            padding-left: 18px;
            background: url("../assets/img/svg/icon-calendar.svg") no-repeat left;
        }
        p {
            color: $grey6B;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0;
            transition: 0.3s ease-in all;
            @media screen and (max-width: $smalltablet) {
                -webkit-line-clamp: 2;
            }
        }
        .readMore {
            position: absolute;
            bottom: 0;
            color: $link;
            background: url("../assets/img/svg/arrow-right.svg") no-repeat right center;
            background-size: 50px auto;
            width: fit-content;
            padding-right: 35px;
            transition: 0.3s ease-in all;
        }
    }

    &__picture {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        picture {
            transition: 0.3s ease-in all;
        }
        img {
            // border: 1px solid $border;
            width: 180px;
            height: auto;
        }
        @media screen and (max-width: $tablet) {
            &::after {
                background-size: auto;
                left: auto;
            }
        }
    }
    &__header {
        @include fontSize14px;
        font-weight: 700;
        padding: 0;
    }
}

.pagination {
    padding: 0 !important;
    margin: 0;
    list-style: none;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    margin-top: 5px;
    li:first-child,
    li:last-child {
        transition: 0.3s ease-in all;
        &:hover {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: $smalltablet) {
        li:first-child,
        li:last-child {
            display: none;
        }
    }
    li {
        color: #333333;
    }
    .page-item {
        line-height: 30px;
        display: inline-block;
        font-size: 16px;
        a {
            font-size: 16px;
            color: #024725;
            -webkit-transition: 0.3s ease all;
            transition: 0.3s ease all;
            padding: 0;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
            &:hover {
                color: #94b520;
            }
        }
    }
    .active {
        background: #abd138;
    }
}
</style>
