
var usunLink = "";

function komunikatZamknij()
{
    document.getElementById('komunikat-warstwa').style.display = 'none';
    document.getElementById('komunikat').style.display = 'none';
}

function komunikatUsunPokaz(adres)
{
    document.getElementById('komunikat-warstwa-usun').style.display = 'inline';
    document.getElementById('komunikat-usun').style.display = 'block';
    usunLink = adres;
}

function komunikatUsunZamknij()
{
    document.getElementById('komunikat-warstwa-usun').style.display = 'none';
    document.getElementById('komunikat-usun').style.display = 'none';
}

function usunPotwierdz()
{
    document.location.href = usunLink;
}

function boxJezyk(id)
{
    if(document.getElementById('box_jezyk_set_'+id).checked == true)
        document.getElementById('box_jezyk_'+id).style.display = "block";
    else
        document.getElementById('box_jezyk_'+id).style.display = "none";
}

function wiadomoscWyzwalacz(id)
{
    if(document.getElementById(id).checked == true)
        document.getElementById('boxWyzwalacz').style.display = "block";
    else
        document.getElementById('boxWyzwalacz').style.display = "none";
}

function liczZnaki(limit, id)
{
    dlugosc = document.getElementById(id).value.length;
    document.getElementById(id + '_licz').innerHTML = "Znaków: " + dlugosc + '/' + limit;
}

$(document).ready(function()
{
    // wybor rodzaju aukcji
    $(".reklama_typ").click(function()
    {
        if($(this).val() == 'gotowa')
        {
            $('.reklama_gotowa').css('display', 'block');
            $('.reklama_wlasna').css('display', 'none');
        }
        else
        {
            $('.reklama_gotowa').css('display', 'none');
            $('.reklama_wlasna').css('display', 'block');
        }

    });
});