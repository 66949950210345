<template>
  <aside class="cookies" v-if="cookiesActive && !cookiePluginActive">
    <div class="container">
      <p>
        Przetwarzamy dane osobowe w celu realizacji usług zgodnie z
        <router-link to="/polityka-prywatnosci/">Polityką prywatności</router-link>.
      </p>
      <button
        type="button"
        @click="saveCookie()"
        title="Zamknij komunikat o cookie"
        class="donotfade close"
      >
        Zamknij
      </button>
    </div>
  </aside>
</template>

<script>

export default {
  data() {
    return {
      cookiesActive: true,
      cookiePluginActive: this.$cookie.get('cookieEmaus')
    };
  },
  name: "Cookies",
  // props: {
  //   msg: String,
  // },
  methods: {
    saveCookie() {
      this.$cookie.set('cookieEmaus', 'Emaus', 1);
      this.cookiesActive = false;
    }
  },
  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
$colorCream: #f5f7da;
$whiteFE: #fefefe;
$mainLight: #abd138;
$mainDarker: #94b520;

#app {
  .cookies {
    top: 0;
    height: fit-content;
    background: $mainDarker;
    padding: 6px 0;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      p {
        padding: 0;
        width: auto;
      }
    }
    .close {
      color: $whiteFE;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 20px;
    }
    a {
      color: $whiteFE;
      text-decoration: underline;
      transition: 0.3s ease-in opacity;
      &:hover {
        opacity: 0.7;
        color: $whiteFE;
      }
    }
    button {
      color: $whiteFE;
      transition: 0.3s ease-in opacity;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
