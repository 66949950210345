<template>
    <section class="main-content">
        <header class="header">
            <h1 class="content__header fade fadein">Błąd 404</h1>
        </header>
        <div>
            <p>Strony nie znaleziono</p>
        </div>
        
    </section>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    name: 'NotFound',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";



</style>
