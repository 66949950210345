<template>
    <section class="main-content">
        <script type="application/javascript" src="https://nowe.platnosci.ngo.pl/campaign.js"></script>

        <header class="header">
            <h1 class="content__header fade fadein">{{ pageData.title }}</h1>
        </header>
        <div v-html="pageData.content"></div>

        <Gallery v-if="pageData.gallery" :galleryItems="zmodyfikowaneDaneDoGalerii" />
    </section>
</template>

<script>
import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            textPages: [],
        };
    },
    components: {
        Gallery: Gallery,
    },
    name: "Tekstowa",
    props: ["pageData"],
    computed: {
        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.pageData.gallery;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    path: `/upload/galeria/${item.zdjecie_sciezka}`,
                    pathMin: `/upload/galeria/_min/${item.zdjecie_sciezka}`,
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";
</style>
